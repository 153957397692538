import { Component } from '@angular/core';
import { OnboardingService } from '../../onboarding-service';
import { AuthService } from '@app/authService';
import { forkJoin, of, switchMap } from 'rxjs';
import { OnboardingBankService } from '@app/services/bank/onboardingBankService';
import { InitService } from '@app/services/common/initService';
import { rigDate } from '@app/components/common/utils/rig-date';
import { CompanyService } from '@app/services/common/companyService';
import { Router } from '@angular/router';
import { BankAccountService } from '@app/services/accounting/bankAccountService';
import { ErrorService } from '@app/services/common/errorService';
import { ONBOARDING_STEPS } from '@app/models';

@Component({
    selector: 'init-eika-confirm-and-create',
    templateUrl: './eika-confirm-and-create.html',
    styleUrls: ['./eika-confirm-and-create.sass'],
})
export class EikaConfirmAndCreate {
    contactEmail: string;
    contactPhone: string;
    contactPerson: string;

    companyKey: string;

    constructor(
        public onboardingService: OnboardingService,
        public authService: AuthService,
        private onboardingBankService: OnboardingBankService,
        private initService: InitService,
        private companyService: CompanyService,
        private bankAccountService: BankAccountService,
        private errorService: ErrorService,
    ) {}

    ngOnInit() {
        this.loadContactInfo();
    }

    loadContactInfo() {
        const customer = this.onboardingService.state()?.Customer;
        if (customer?.ContactPerson && customer?.ContactEmail) {
            // && customer?.ContactPhone
            return;
        }

        this.initService.getCurrentUserInfo().subscribe({
            next: (res) => {
                if (res) {
                    let phoneNumber = res.authPhoneNumber;
                    // split on hyphen if it includes country codes, i.e '47-13371337'
                    if (phoneNumber?.includes('-')) {
                        phoneNumber = phoneNumber.split('-')[1];
                    }

                    this.setContactInfo(res.email, phoneNumber || '', res.fullName || res.userName);
                } else {
                    this.setContactInfoBasedOnToken();
                }
            },
            error: (err) => {
                this.setContactInfoBasedOnToken();
            },
        });
    }

    setContactInfoBasedOnToken() {
        const token = this.authService.decodedToken();
        this.setContactInfo(token.email, '', token.name);
    }

    setContactInfo(email: string, phone: string, name: string) {
        this.onboardingService.setCustomer({
            ...(this.onboardingService.state()?.Customer ?? {}),
            ContactEmail: email,
            ContactPhone: phone,
            ContactPerson: name,
        });

        this.onboardingService.setCurrentStepIsValid(true);
    }

    createCustomer() {
        this.onboardingService.createCustomer().subscribe({
            next: () => this.onCustomerCreated(),
            error: (err) => {
                this.onboardingService.busyCreatingCompany = false;
                const errorMessageLower = this.errorService.extractMessage(err)?.toLowerCase();
                if (errorMessageLower.includes('user has insufficient access to register company')) {
                    this.onboardingService.setCurrentStepByType(ONBOARDING_STEPS.VALIDATION_ERROR);
                }
            },
        });
    }

    private async onCustomerCreated() {
        const isContractActivation = window.location.href.includes('contract-activation');
        let company;
        try {
            company = await this.onboardingService.checkCompanyCreationStatus().toPromise();
            this.companyKey = company.Key;
            await this.acceptLicenseAgreements();

            if (this.onboardingService.hasSelectedEndUserAgreement()) {
                await this.connectBank();
                await this.createCompanyBankAccounts();
                await this.createBankAgreement();
            }
        } catch (e) {}

        if (isContractActivation) {
            const body = {
                ID: this.authService.currentUser.License.Company.ID,
                EndDate: rigDate(),
                CompanyKey: this.authService.currentUser.License.Company.Key,
                OrgNumber: null,
                CompanyName: this.authService.currentUser.License.Company.Name,
            };

            const goToNewCompany = () => {
                this.companyService.invalidateCache();
                this.onboardingService.clearSessionStorage();
                this.authService.setActiveCompany(company, '/');
            };

            this.onboardingService.deactivateDemoCompany(body.ID, body).subscribe({
                next: () => {
                    goToNewCompany();
                },
                error: () => {
                    goToNewCompany();
                },
            });
        } else {
            this.onboardingService.busyCreatingCompany = false;
            this.onboardingService.goNext();
        }
    }

    acceptLicenseAgreements() {
        return forkJoin([
            this.initService.acceptUserAgreement(this.companyKey),
            this.initService.acceptCustomerAgreement(this.companyKey),
        ]).toPromise();
    }

    connectBank() {
        const bankIdentifier = this.onboardingService.selectCompanyState()?.selectedEndUserAgreement?.BankIdentifier;
        const state = this.onboardingService.state();
        return this.onboardingBankService
            .connectBank(
                this.companyKey,
                state.Customer.OrgNumber,
                bankIdentifier,
                null,
                state.BankServices.HasPayments,
                state.BankServices.HasPreApprovedPayment,
                state.BankServices.HasStatements,
            )
            .toPromise();
    }

    createBankAgreement() {
        const state = this.onboardingService.state();
        return this.onboardingBankService
            .createBankAgreement(
                state.Customer.ContactPhone,
                this.companyKey,
                state.BankServices.HasPayments,
                state.BankServices.HasPreApprovedPayment,
                state.BankServices.HasStatements,
                state.BankServices.BankStatementStartDate,
            )
            .toPromise();
    }

    createCompanyBankAccounts() {
        const payload = this.onboardingService.getCompanyBankAccountsPayload();
        if (!payload.length) return;
        return this.bankAccountService.createCompanyBankAccounts(payload, this.companyKey).toPromise();
    }

    createDemoCustomer() {
        this.initService
            .getTemplates()
            .pipe(
                switchMap((templates) => {
                    let template = templates.find((t) => t.IsTest);
                    return of(template);
                }),
                switchMap((template) => {
                    const payload = this.onboardingService.getDemoCustomerPayload(
                        this.authService.decodedToken(),
                        template?.Key ?? null,
                    );
                    return this.onboardingService.createDemoCustomer(payload);
                }),
            )
            .subscribe(async () => {
                const company = await this.onboardingService.checkCompanyCreationStatus().toPromise();
                await this.initService.acceptUserAgreement(company.Key).toPromise();

                this.onboardingService.clearSessionStorage();
                this.onboardingService.busyCreatingCompany = false;
                this.authService.setActiveCompany(company, '/');
            });
    }
}
